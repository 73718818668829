import React from 'react';
import PropTypes from 'prop-types';
import { enableFeatureInApp } from 'yoda-site-components/lib/helpers/Utils/features';
import get from 'lodash/get';
import LaunchNLearnNew from './LaunchNLearnNew';
import LaunchNLearnOld from './LaunchNLearnOld';

const LaunchNLearnWrapper = (props) => {
    const androidVersion = get(props, 'preferences.lnlVersions.androidMinVersion', '');
    const enableNewLnlforAndriod = enableFeatureInApp({
        iosMinVersion: '',
        androidMinVersion: androidVersion,
    });
    /* istanbul ignore next */
    if (props?.isNativeAndroid && !enableNewLnlforAndriod) {
        /* eslint-disable react/jsx-props-no-spreading */
        return <LaunchNLearnOld {...props} />;
    }
    /* eslint-disable react/jsx-props-no-spreading */
    return <LaunchNLearnNew {...props} />;
};
LaunchNLearnWrapper.propTypes = {
    isNativeAndroid: PropTypes.bool.isRequired,
};
export default LaunchNLearnWrapper;
