import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import * as signInActions from 'yoda-site-components/lib/actions/SignInAction';
import * as createAccountActions from 'yoda-site-components/lib/actions/CreateAccountActions';
import * as signInSliderActions from 'yoda-site-components/lib/actions/SignInSliderActions';
import * as nativeAppActions from 'yoda-site-components/lib/actions/NativeAppAction';
import { selectKeepMeLoggedInThrottleFF } from 'yoda-site-components/lib/selectors/KeepMeLoggedInSelector';
import { getUserDetails } from 'yoda-site-components/lib/actions/AccountAction';
import { getAccount } from '../../../actions/GetAccountActions';
import * as newLnLActions from '../../../actions/LaunchNLearnNewActions';
import * as rewardsActions from '../../../actions/RewardsAction';
import LaunchNLearnWrapper from './LaunchNLearnWrapper';

const mapStateToProps = (state) => ({
    createAccountDetails: state.CreateAccount,
    signInDetails: state.signInDetails,
    launchNLearnDetails: state.launchNLearnDetails,
    newLaunchNLearnDetails: state.newLaunchNLearnDetails,
    getAccountDetails: state.getAccountDetails,
    deviceType: state.context.deviceType,
    preferences: state.context.preferences,
    messagesTexts: state.context.messagesTexts,
    featureFlags: state.context.featureFlags,
    rewardsDetails: state.rewardsDetails.details,
    userState: get(state, 'accounts.userProfile.userState', '0'),
    accountId: get(state, 'accounts.userProfile.accountId', ''),
    enableKeepMeLoggedIn: selectKeepMeLoggedInThrottleFF(state),
    emailFocus: get(state, 'signInSliderDetails.emailFocus'),
    isNative: get(state, 'context.isNative', false),
    isNativeAndroid: get(state, 'context.isNativeAndroid', false),
});

const mapDispatchToProps = (dispatch) => ({
    newLnLActions: bindActionCreators(newLnLActions, dispatch),
    getAccount: bindActionCreators(getAccount, dispatch),
    rewardsActions: bindActionCreators(rewardsActions, dispatch),
    signInActions: bindActionCreators(signInActions, dispatch),
    createAccountActions: bindActionCreators(createAccountActions, dispatch),
    signInSliderActions: bindActionCreators(signInSliderActions, dispatch),
    getUserDetails: bindActionCreators(getUserDetails, dispatch),
    nativeAppActions: bindActionCreators(nativeAppActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LaunchNLearnWrapper);
